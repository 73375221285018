//
// The old styling for content carousels : still used by CarouselRow
// These carousels don't use swipers inbuilt Navigation
// instead nav buttons and rtl switch is handled in CarouselRow component
//

.carousel_new {
  position: relative;
  overflow: hidden;
  @include noselect();
  @include perspective(1000px);
  width: 100%;
}

.carousel_new .carousel_area {
  position: relative;
  overflow: hidden;
  margin-right: 1px;
}
.carousel_new {
  .nav_button {
    cursor: pointer;
    height: 74px;
    width: 74px;
    position: absolute;
    top: 35%;
    @include translatecentery();
    background: #1e1a4d;
    background-size: 45% auto;
    background-position: center;
    background-repeat: no-repeat;
    &.nav_left {
      left: 0;
      @include backgroundimage('refresh-icons/arrow_left.svg');
      @include transformorigin(0% 50%);
      @include carouselArrowsInActive(60deg);
    }
    &.nav_right {
      right: 0;
      @include backgroundimage('refresh-icons/arrow_right.svg');
      @include transformorigin(100% 50%);
      @include carouselArrowsInActive(90deg);
    }
    &.disabled {
      display: none;
    }
  }
}

html[dir='rtl'] {
  .carousel_new {
    .nav_button {
      &.nav_left {
        left: 0;
      }
      &.nav_right {
        right: 0;
      }
    }
  }
}

.carousel_new {
  .nav_button:hover {
    background-size: 60% auto;
  }
  &:hover .nav_button {
    @include carouselArrowsActive();
    &.disabled {
      display: none;
    }
    &.show.disabled {
      display: none;
    }
  }
  .nav_button.show {
    @include carouselArrowsActive();
    &.disabled {
      display: none;
    }
  }
}

