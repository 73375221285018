//
// The new styling for app screenshot and related items carousels
// These carousels use swipers inbuilt Navigation
//

.carousel_new {
  .swiper_btn {
    cursor: pointer;
    height: 74px;
    width: 74px;
    position: absolute;
    top: 35%;
    @include translatecentery();
    background: #1e1a4d;
    background-size: 45% auto;
    background-position: center;
    background-repeat: no-repeat;

    &.swiper_button_prev {
      left: 0px;
      @include backgroundimage('refresh-icons/arrow_left.svg');
      @include transformorigin(0% 50%);
      @include carouselArrowsInActive(60deg);
    }

    &.swiper_button_next {
      right: 0px;
      @include backgroundimage('refresh-icons/arrow_right.svg');
      @include transformorigin(100% 50%);
      @include carouselArrowsInActive(90deg);
    }

    &.disabled {
      display: none;
    }
  }

  .swiper_btn:hover {
    background-size: 60% auto;
  }

  &:hover .swiper_btn {
    @include carouselArrowsActive();

    &.disabled {
      display: none;
    }

    &.show.disabled {
      display: none;
    }
  }

  .swiper_btn.show {
    @include carouselArrowsActive();

    &.disabled {
      display: none;
    }
  }
}

html[dir='rtl'] {
  .carousel_new {
    .swiper_btn {
      &.swiper_button_prev {
        right: 0;
        @include backgroundimage('icons/arrow_right.svg');
        @include transformorigin(100% 50%);
        @include carouselArrowsInActive(90deg);
        left: auto;
      }

      &.swiper_button_next {
        left: 0;
        @include backgroundimage('icons/arrow_left.svg');
        @include transformorigin(0% 50%);
        @include carouselArrowsInActive(60deg);
        right: auto;
      }
    }

    .swiper_btn:hover {

    }

    &:hover .swiper_btn {
      @include carouselArrowsActive();

      &.disabled {
        display: none;
      }

      &.show.disabled {
        display: none;
      }
    }

    .swiper_btn.show {
      @include carouselArrowsActive();

      &.disabled {
        display: none;
      }
    }
  }
}
