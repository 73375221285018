$image_path : "../images/";
$font_path : "../fonts/";

$color_facebook: #3B5998;
$color_twitter: #00B0ED;
$color_linkedin: #0065a0;

$bounce: cubic-bezier(0.47, 2.02, 0.31, -0.36);
$mildbounce: cubic-bezier(0.47, 3.32, 0.31, -0.36);
$superbounce: cubic-bezier(0.47, 5.02, 0.31, -0.36);

$padding: 20px;
$sectionMargin: 30px;


$labelColor:rgba(0,0,0,0.3);

// Colors override
$color_blue: #38d1db;
$color_yellow: #f7e003;
$color_orange: #ff634a;
$color_green: #5ced6e;
$color_purple: #400a36;
$iconHeight: 48px;

/*MIXINS */
@mixin alignself {
  -ms-flex-item-align: center;
  align-self:          center;
}

@mixin backgroundimage($_image) {
  background-image:  url(#{$image_path}#{$_image});
  background-repeat: no-repeat;
}

@mixin noselect {
  -webkit-touch-callout: none;
  -webkit-user-select:   none;
  -khtml-user-select:    none;
  -moz-user-select:      none;
  -ms-user-select:       none;
  user-select:           none;
  cursor:                default;
}

@mixin select {
  -webkit-touch-callout: inherit;
  -webkit-user-select:   inherit;
  -khtml-user-select:    inherit;
  -moz-user-select:      inherit;
  -ms-user-select:       inherit;
  user-select:           inherit;
}

@mixin placeholder($color, $size: '') {
  #{if(&, "&", "*")}::-webkit-input-placeholder {
    color: $color;
    @if $size != '' {
      font-size: $size;
    }
  }
  #{if(&, "&", "*")}:-moz-placeholder {
    color: $color;
    @if $size != '' {
      font-size: $size;
    }
  }
  #{if(&, "&", "*")}::-moz-placeholder {
    color: $color;
    @if $size != '' {
      font-size: $size;
    }
  }
  #{if(&, "&", "*")}:-ms-input-placeholder {
    color: $color;
    @if $size != '' {
      font-size: $size;
    }
  }
}

@mixin transform($extra:null) {
  -webkit-transform: $extra;
  -ms-transform:     $extra;
  transform:         $extra;
}

@mixin transformorigin($extra:null) {
  -webkit-transform-origin: $extra;
  -ms-transform-origin:     $extra;
  transform-origin:         $extra;
}

@mixin translatecenter($extra:null) {
  -webkit-transform: translate(-50%, -50%) $extra;
  -ms-transform:     translate(-50%, -50%) $extra;
  transform:         translate(-50%, -50%) $extra;
}

@mixin translatecenterx($extra:null) {
  -webkit-transform: translate(-50%, 0) $extra;
  -ms-transform:     translate(-50%, 0) $extra;
  transform:         translate(-50%, 0) $extra;
}

@mixin translatecentery($extra:null) {
  -webkit-transform: translate(0%, -50%) $extra;
  -ms-transform:     translate(0%, -50%) $extra;
  transform:         translate(0%, -50%) $extra;
}

@mixin translatenone {
  -webkit-transform: translate(0%, 0%);
  -ms-transform:     translate(0%, 0%);
  transform:         translate(0%, 0%);
}

@mixin transformstyle($val) {
  -webkit-transform-style: $val;
  -ms-transform-style:     $val;
  transform-style:         $val;
}

@mixin perspective($val) {
  -webkit-perspective: $val;
  -ms-perspective:     $val;
  perspective:         $val;
}

@mixin perspectiveorigin($val) {
  -webkit-perspective-origin: $val;
  -ms-perspective-origin:     $val;
  perspective-origin:         $val;
}

@mixin backface {
  -webkit-backface-visibility: hidden;
  backface-visibility:         hidden;
}

@mixin accelerate {
  -webkit-transform:           translate3d(0, 0, 0);
  transform:                   translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility:         hidden;
}

@mixin displayflex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin flex($count: 1) {
  -webkit-flex: $count; /* Safari 6.1+ */
  -ms-flex:     $count; /* IE 10 */
  flex:         $count;
}

@mixin center {
  text-align: center;
}

@mixin flexdirection($dir) {
  -webkit-flex-direction: $dir;
  -ms-flex-direction:     $dir;
  flex-direction:         $dir;
}

@mixin order($order) {
  -webkit-order: $order;
  -ms-order:     $order;
  order:         $order;
}

//@mixin calc($prop, $val) {
//  #{$prop}: calc($val);
//  #{$prop}: -webkit-calc($val);
//}

@mixin stroke($width, $color) {
  $width: $width + 0;
  $shadow: 0 0 0 transparent;
  $i: 0;
  $w: 1;

  @while ($i < $width) {
    $i: $i + 1;
    $j: 0;
    $w: $w + 2;

    @for $r from 1 through $w {
      @for $c from 1 through $w {
        $x: $c - math.ceil(math.div($w / 2));
        $y: $r - math.ceil(math.div($w / 2));

        $shadow: #{$shadow}, #{$x}px #{$y}px 0 $color;
      }
    }
  }

  text-shadow: $shadow;
}

@mixin animationfill($mode:forwards) {
  -webkit-animation-fill-mode: $mode; /* Safari 4.0 - 8.0 */
  animation-fill-mode:         $mode;
}

@mixin animation($animate...) {
  $max: length($animate);
  $animations: '';

  @for $i from 1 through $max {
    $animations: #{$animations + nth($animate, $i)};

    @if $i < $max {
      $animations: #{$animations + ", "};
    }
  }
  -webkit-animation: $animations;
  animation:         $animations;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}

@media(max-width: 767px) {
  .desktoponly {
    display: none !important;
  }
}

@media(min-width: 768px) {
  .mobileonly {
    display: none !important;
  }
}

@mixin carouselArrowsActive() {
  z-index: 99;
  @include transform(rotateY(0deg));
  -webkit-transition: background-size 0.3s $superbounce, -webkit-transform 0.3s, z-index 0s;
  transition: background-size 0.3s $superbounce, transform 0.3s, z-index 0s;
  opacity: 1;
}
@mixin carouselArrowsInActive($deg) {
  z-index: -9999;
  opacity: 0;
  @include transform(rotateY(-#{$deg}));
  -webkit-transition: background-size 0.3s $superbounce, -webkit-transform 0.3s, opacity 0.3s, z-index 0s 0.3s;
  transition: background-size 0.3s $superbounce, transform 0.3s, opacity 0.3s, z-index 0s 0.3s;
}
