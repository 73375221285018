@mixin fontsize($font-size: 16) {
  $pxFontValue: math.div($font-size, 10);
  font-size:   #{$font-size}px;
  font-size:   #{$pxFontValue}rem;
  line-height: 1.1;
}

@mixin leading($line-height) {
  $pxLineValue: math.div(($line-height), 10);
  line-height: #{$line-height}px;
  line-height: #{$pxLineValue}rem;
}

b {
  font-weight: bold;
}

em {
  font-style: italic;
}
